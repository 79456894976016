import { Time } from "~/types/general";

export const useTimeGet = (timeString: string): Time => {
  if (!timeString) return;

  const timeArr = timeString.split(":");

  return {
    hours: +timeArr[0],
    minutes: +timeArr[1],
    seconds: +timeArr[2],
  };
};
